import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://palmdale.app.1life.com',
  myoneServer: 'https://palmdale.app.1life.com',
  newRecaptchaSiteKey: '6LfaIKgpAAAAAJ2hI-pnKi6i9hIQPsmxxJ2aqMPY',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-palmdale',
  },
  launchDarklyClientId: '605015b1824e970bccf1872c',
};
